<template>
    <br>
    <Records />
</template>
    
<script>
import Records from '../components/Records.vue';


export default {
    components: {
        Records
    }
}
</script>
    

