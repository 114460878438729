<template>
    <br>
    <br>
    <Rules />
</template>

<script>
import Rules from '../components/Rules.vue';


export default {
    components: {
        Rules
    }
}
</script>