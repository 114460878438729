<template>
    <Awards />
    <br>
    <PostComponent />
</template>
    

<script>
import Awards from '../components/Awards.vue';
import PostComponent from "@/components/PostComponent.vue";


export default {
    components: {
        Awards,
        PostComponent
    }
}
</script>
    