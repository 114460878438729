<template>
    <br>
    <Admin />
</template>
    

<script>
import Admin from "@/components/Admin.vue";


export default {
    components: {
        Admin
    }
}
</script>